<template>
  <TransitionRoot appear :show="props.open" as="template">
    <Dialog
      as="div"
      @close="emit('closeCart')"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
    >
      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="ease-in-out duration-500"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-500"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true"> &#8203; </span>

          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <DialogPanel
              class="fixed inset-y-0 right-0 max-w-md w-11/12 flex flex-col bg-white text-center"
            >
              <div class="flex items-start justify-between p-4 pb-2 bg-white">
                <DialogTitle class="text-lg text-slate-800 font-semibold" as="h3">
                  Carrinho
                </DialogTitle>
                <div class="ml-3 flex h-7 items-center">
                  <button
                    type="button"
                    class="-m-2 p-2 text-slate-400 hover:text-slate-500"
                    @click="emit('closeCart')"
                  >
                    <span class="sr-only">Fechar</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>

              <Cart :go-to-checkout="goToCheckout" :go-to-products="() => emit('closeCart')" />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline/index.js'
import Cart from './Cart.vue'
import { CHECKOUT_URI } from '../enums/checkout'

interface Props {
  open: boolean
}

const emit = defineEmits<{
  (e: 'closeCart'): void
}>()

const goToCheckout = () => {
  location.href = CHECKOUT_URI
}

const props = withDefaults(defineProps<Props>(), {
  open: false,
})

let xDown = null
let yDown = null

function getTouches(evt) {
  return evt.touches || evt.originalEvent.touches
}

function handleTouchStart(evt) {
  const firstTouch = getTouches(evt)[0]
  xDown = firstTouch.clientX
  yDown = firstTouch.clientY
}

function handleTouchMove(evt) {
  if (!xDown || !yDown) {
    return
  }

  const xUp = evt.touches[0].clientX
  const yUp = evt.touches[0].clientY

  const xDiff = xDown - xUp
  const yDiff = yDown - yUp

  if (Math.abs(xDiff) > Math.abs(yDiff) && xDiff < 0) {
    emit('closeCart')
  }

  xDown = null
  yDown = null
}
</script>
